import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import PageBanner from "../../components/Common/PageBanner"
import Footer from "../../components/_App/Footer"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import GetInTouch from "../../components/get-in-touch"
import * as Icon from "react-feather"
import link from "../../assets/images/link.png"

const OurRecipe = ({ data }) => {
  // console.log("OurRecipeData", data)
  const mainData = data?.mainData?.nodes[0]?.childMdx
  const recipeFeatures = data?.recipeFeatures?.nodes
  const recipeFeaturesSummary = data?.recipeFeaturesSummary?.nodes

  const onViewMoreClick = (event: Event, index: number) => {
    event.preventDefault()

    const buttonElement = document.getElementById("recipe-more-button" + index)
    buttonElement.innerHTML =
      buttonElement.innerHTML === "View More" ? "View Less" : "View More"

    const element = document.getElementById("recipe-detail" + index)
    element.classList.toggle("hide")
  }
  const linkId = (id: any) => {
    return id
      ?.replace(/[^a-zA-Z ]/g, "")
      ?.replaceAll(" ", "-")
      ?.toLowerCase()
  }

  return (
    <Layout>
      <SEO
        title="Tarkashilpa -Web Application Development Services Company"
        description={`App Development is a tough, there are no "One Size Fits All" methodology. Tarkashilpa comes with the Perfect App Development Methodology which suits each and every business requirements`}
        keywords="App Development Methodology"
        url={`/our-recipe`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      <PageBanner pageTitle={mainData?.frontmatter?.title} />

      {/* <div className="case-study-banner">
        <div className="container">
          <div className="case-study-content-wrapper">
            <div className="case-study-content">
              <h1>{mainData?.frontmatter?.title}</h1>
              <p>{mainData?.frontmatter?.summary}</p>
            </div>
          </div>
        </div>
        <div className="banner-image">
          <div className="overlay"></div>
          <figure>
            <img
              src={mainData?.frontmatter?.bannerImg?.publicURL}
              alt={mainData?.frontmatter?.bannerImgAlt}
            />
          </figure>
        </div>
      </div> */}

      <div className="our-recipe-area ptb-50 bg-f7fafd">
        <div className="container">
          <MDXRenderer>{mainData?.body}</MDXRenderer>

          {mainData?.frontmatter?.phases &&
            mainData?.frontmatter?.phases.length > 0 && (
              <ul className="recipe-phases d-none d-md-block">
                {mainData?.frontmatter?.phases.map(
                  (list: any, index: number) => (
                    <li className="list" key={index + 1}>
                      <div className="section-title">
                        {/* <h2>
                          Phase{" "}
                          {index + 1 < mainData?.frontmatter?.phases.length
                            ? index + 1
                            : "N"}
                        </h2> */}
                        <h2>Project plan</h2>
                        <div className="phase-img">
                          <img
                            src={list?.publicURL}
                            alt={"phase" + (index + 1)}
                          />
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            )}

          {mainData?.frontmatter?.phasesResponsive &&
            mainData?.frontmatter?.phasesResponsive.length > 0 && (
              <ul className="recipe-phases d-block d-md-none">
                {mainData?.frontmatter?.phasesResponsive.map(
                  (list: any, index: number) => (
                    <li className="list" key={index + 1}>
                      <div className="section-title">
                        <h2>
                          Phase{" "}
                          {index + 1 < mainData?.frontmatter?.phases.length
                            ? index + 1
                            : "N"}
                        </h2>
                        <div className="phase-img">
                          <img
                            src={list?.publicURL}
                            alt={"phase" + (index + 1)}
                          />
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            )}

          <div className="row justify-content-center">
            <div className="col-12">
              {recipeFeatures &&
                recipeFeatures.length > 0 &&
                recipeFeatures.map((list: any, index: number) => (
                  <div
                    className="single-features portfolio-list recipe-tile"
                    key={index + 1}
                  >
                    <div className="row align-items-start">
                      <div className="col-lg-4">
                        <img
                          src={list?.childMdx?.frontmatter?.thumbImg?.publicURL}
                          alt={list?.childMdx?.frontmatter?.thumbImgAlt}
                        />
                      </div>
                      <div className="col-lg-8">
                        <h3
                          className="hover-link"
                          id={linkId(list?.childMdx?.frontmatter?.title)}
                        >
                          <span>
                            <a
                              href={`#${linkId(
                                list?.childMdx?.frontmatter?.title
                              )}`}
                            >
                              <img src={link} alt="" />
                            </a>
                            {list?.childMdx?.frontmatter?.title}
                          </span>
                        </h3>
                        <MDXRenderer>
                          {
                            recipeFeaturesSummary.find(
                              (summary: any) =>
                                summary?.childMdx?.frontmatter?.key ===
                                list?.childMdx?.frontmatter?.key
                            )?.childMdx?.body
                          }
                        </MDXRenderer>
                        {list?.childMdx?.wordCount?.paragraphs > 0 && (
                          <Link
                            to="/"
                            className="recipe-more-button"
                            id={"recipe-more-button" + index}
                            onClick={(event: any) =>
                              onViewMoreClick(event, index)
                            }
                          >
                            View More
                          </Link>
                        )}
                      </div>
                    </div>

                    <div
                      className="row our-recipe-features-details hide"
                      id={"recipe-detail" + index}
                    >
                      <div className="col-12">
                        <MDXRenderer>{list?.childMdx?.body}</MDXRenderer>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
      <Footer />
    </Layout>
  )
}

export default OurRecipe
export const query = graphql`
  query OurRecipe {
    mainData: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipe" }
        name: { eq: "index" }
        childMdx: { slug: { eq: "" } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
            title
            summary
            bannerImg {
              publicURL
            }
            bannerImgAlt
            phases {
              publicURL
            }
            phasesResponsive {
              publicURL
            }
          }
          body
        }
      }
    }
    recipeFeatures: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipe" }
        name: { eq: "index" }
        childMdx: { slug: { ne: "" } }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            order
          }
          wordCount {
            paragraphs
          }
          body
        }
      }
    }
    recipeFeaturesSummary: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipe" }
        name: { eq: "summary" }
        childMdx: { slug: { ne: "" } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
          }
          body
        }
      }
    }
  }
`
